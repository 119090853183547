import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LoadingContext = createContext();

function LoadingContextProvider({ children }) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}

LoadingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

LoadingContextProvider.defaultProps = {};

export default LoadingContextProvider;
