import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
// import useRoutes from 'hooks/useRoutes';
import useDialog from 'hooks/useDialog';

function AuthRoute({ children, ...rest }) {
  const { showLogin } = useDialog();

  // const {route} = useRoutes();

  const { token } = useAuth();

  useEffect(() => {
    if (!token) {
      showLogin();
    }
  }, [token, showLogin]);

  return (
    <Route
      {...rest}
      render={() => {
        return token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {},
            }}
          />
        );
      }}
    />
  );
}

AuthRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthRoute;
