import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import useTags from 'hooks/useTags';
import useRoutes from 'hooks/useRoutes';

import drill from 'assets/svg/tags/drill.svg';
import grassCutter from 'assets/svg/tags/grass_cutter.svg';
import vehicle from 'assets/svg/tags/vehicle.svg';
import caravan from 'assets/svg/tags/caravan.svg';
import grill from 'assets/svg/tags/grill.svg';

const ICONS = {
  'Elektrické a ruční nářadí': drill,
  'Zahrada / Dům': grassCutter,
  'Stavba / Stroje': vehicle,
  'Auto / Moto': caravan,
  'Volný čas': grill,
};

function AnnoncesMenu({ opened, handleClose }) {
  const { route } = useRoutes();

  const { masterTags } = useTags();

  const { pathname } = useLocation();

  useEffect(() => {
    handleClose();
  }, [pathname, handleClose]);

  return (
    <nav
      className={['mdc-annonces-menu', opened && 'mdc-annonces-menu--opened']
        .filter((el) => el)
        .join(' ')}
    >
      <div className="mdc-layout-grid">
        <div className="mdc-annonces-menu__surface">
          {masterTags
            .filter((tag) => ICONS[tag.name])
            .map((tag) => (
              <Link
                className="mdc-annonces-menu__tag"
                key={tag.id}
                onClick={handleClose}
                to={{
                  pathname: route('annonces.index'),
                  state: { masterTag: String(tag.id) },
                }}
              >
                <img
                  alt={tag.name}
                  className="mdc-annonces-menu__tag__icon"
                  src={ICONS[tag.name]}
                />
                <span className="mdc-annonces-menu__tag__label">
                  {tag.name}
                </span>
              </Link>
            ))}
        </div>
      </div>
    </nav>
  );
}

AnnoncesMenu.propTypes = {
  handleClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

AnnoncesMenu.defaultProps = {};

export default AnnoncesMenu;
