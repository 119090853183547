import React, { createContext, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';

import MdcSnackBar from 'mdc/MdcSnackBar';

export const SnackContext = createContext();

export const INIT = Symbol();
export const ENQUEUE = Symbol();
export const POP = Symbol();

function init() {
  return { queue: [] };
}

function reducer(state, action) {
  switch (action.type) {
    case POP:
      return { queue: state.queue.slice(1) };
    case ENQUEUE:
      return {
        queue: [
          ...state.queue,
          {
            key: performance.now(),
            content: action.content,
            theme: action.theme,
          },
        ],
      };
    case INIT:
      return init();
    default:
      throw new Error();
  }
}

function SnackContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, null, init);

  const enqueu = useCallback(
    (content, theme) => {
      dispatch({ type: ENQUEUE, content: content, theme: theme });
    },
    [dispatch],
  );

  const info = useCallback(
    (content) => {
      enqueu(content, 'info');
    },
    [enqueu],
  );

  const error = useCallback(
    (content) => {
      enqueu(content, 'error');
    },
    [enqueu],
  );

  const success = useCallback(
    (content) => {
      enqueu(content, 'success');
    },
    [enqueu],
  );

  const clear = useCallback(() => {
    dispatch({ type: INIT });
  }, [dispatch]);

  const pop = useCallback(() => {
    dispatch({ type: POP });
  }, [dispatch]);

  return (
    <SnackContext.Provider
      value={{
        dispatch,
        info,
        success,
        error,
        clear,
        pop,
      }}
    >
      {state.queue[0] && (
        <MdcSnackBar
          content={state.queue[0].content}
          key={state.queue[0].key}
          theme={state.queue[0].theme}
        />
      )}
      {children}
    </SnackContext.Provider>
  );
}

SnackContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

SnackContextProvider.defaultProps = {};

export default SnackContextProvider;
