import React, { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { LocaleContext } from './LocaleContext';

export const RouteContext = createContext();

function RouteContextProvider({ children }) {
  const [routes] = useState(window.preload.routes || {});

  const { locale } = useContext(LocaleContext);

  const route = useCallback(
    (route, params = {}) => {
      if (routes[route] && routes[route][locale] && routes[route][locale].url) {
        let href = routes[route][locale].url;

        href = href.replace('{', ':').replace('}', '');

        if (params && typeof params === 'object') {
          Object.keys(params).forEach((key) => {
            href = href.replace(':' + key, params[key]);
          });
        }

        return href;
      }

      throw new Error(route);
    },
    [locale, routes],
  );

  const fullroute = useCallback(
    (rou, params = {}) => {
      return window.preload.fullurl + route(rou, params);
    },
    [route],
  );

  const label = useCallback(
    (route, params = {}) => {
      if (
        routes[route] &&
        routes[route][locale] &&
        routes[route][locale].label
      ) {
        let href = routes[route][locale].label;

        href = href.replace('{', ':').replace('}', '');

        if (params && typeof params === 'object') {
          Object.keys(params).forEach((key) => {
            href = href.replace(':' + key, params[key]);
          });
        }

        return href;
      }

      throw new Error();
    },
    [locale, routes],
  );

  return (
    <RouteContext.Provider value={{ routes, route, label, fullroute }}>
      {children}
    </RouteContext.Provider>
  );
}

RouteContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

RouteContextProvider.defaultProps = {};

export default RouteContextProvider;
