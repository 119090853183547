import React, { createContext, useReducer, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

export const SET_AUTH = Symbol();
export const INIT = Symbol();

export const AuthContext = createContext();

import useRoutes from 'hooks/useRoutes';

function init() {
  return JSON.parse(localStorage.getItem('auth')) || {};
}

function reducer(_, action) {
  switch (action.type) {
    case SET_AUTH:
      localStorage.setItem('auth', JSON.stringify(action.data));

      return { ...action.data };
    case INIT:
      localStorage.clear();

      return init();
    default:
      throw new Error();
  }
}

function AuthContextProvider({ children }) {
  const history = useHistory();

  const { route } = useRoutes();

  const [state, dispatch] = useReducer(reducer, null, init);

  const login = useCallback((payload) => {
    dispatch({ type: SET_AUTH, data: payload });
  }, []);

  const clear = useCallback(() => {
    dispatch({ type: INIT });
  }, []);

  const logout = useCallback(() => {
    history.replace(route('auth.logout'));
  }, [history, route]);

  const token = useMemo(() => {
    return state.jwt ? state.jwt.access_token : null;
  }, [state.jwt]);

  const user = useMemo(() => {
    return state.user;
  }, [state.user]);

  const id = useMemo(() => {
    return user ? user.id : null;
  }, [user]);

  return (
    <AuthContext.Provider
      value={{ state, dispatch, login, logout, token, id, user, clear }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthContextProvider.defaultProps = {};

export default AuthContextProvider;
