import React, { useRef, useEffect, useCallback } from 'react';

import MdcDialog from 'mdc/MdcDialog';
import MdcTextInput from 'mdc/MdcTextInput';
import MdcButton from 'mdc/MdcButton';
import MdcLayoutGridInner from 'mdc/MdcLayoutGridInner';
import MdcLayoutGridCell from 'mdc/MdcLayoutGridCell';

import { AUTH_FORGOT_REQUEST } from 'routes';

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';
import useSnack from 'hooks/useSnack';

function ForgotDialog() {
  const formRef = useRef();

  const { loading, setLoading } = useLoader();

  const { token } = useAuth();

  const { request, errorsFor } = useApi();

  const { info } = useSnack();

  const { close } = useDialog();

  useEffect(() => {
    if (token) {
      close();
    }
  }, [token, close]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);

      request(AUTH_FORGOT_REQUEST, formRef.current)
        .then(
          () => {
            info('E-mail s formulářem na nové heslo odeslán');
            close();
          },
          () => {},
        )
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, request, info, close],
  );

  return (
    <MdcDialog title="Zapomenuté heslo">
      <form onSubmit={handleSubmit} ref={formRef}>
        <MdcLayoutGridInner>
          <MdcLayoutGridCell span={12}>
            <MdcTextInput
              errors={errorsFor('email')}
              full
              label="E-mailová adresa"
              name="email"
              outlined
              required
              type="email"
            />
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcButton
              big
              disabled={loading}
              fullwidth
              label="Obnovit heslo"
              secondary
              type="submit"
              unelevated
            />
          </MdcLayoutGridCell>
        </MdcLayoutGridInner>
      </form>
    </MdcDialog>
  );
}

export default ForgotDialog;
