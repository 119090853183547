import React from 'react';
import PropTypes from 'prop-types';

function MdcLayoutGridInner({ children }) {
  return <div className="mdc-layout-grid__inner">{children}</div>;
}

MdcLayoutGridInner.propTypes = {
  children: PropTypes.node.isRequired,
};

MdcLayoutGridInner.defaultProps = {};

export default MdcLayoutGridInner;
