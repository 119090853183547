import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import UserLogo from 'fm/UserLogo';

import useRoutes from 'hooks/useRoutes';

function UserBar({ user, link, handleClick }) {
  const { route } = useRoutes();

  /* eslint-disable react/forbid-component-props */
  if (link) {
    return (
      <Link
        className="mdc-user-bar"
        to={route('users.show', { user: user.id })}
      >
        <UserLogo user={user} />
        <span className="mdc-user-bar__name">{user.fullname}</span>
      </Link>
    );
  }
  /* eslint-enable react/forbid-component-props */

  return (
    <div
      className="mdc-user-bar"
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex="-1"
    >
      <UserLogo user={user} />
      <span className="mdc-user-bar__name">{user.fullname}</span>
    </div>
  );
}

UserBar.propTypes = {
  handleClick: PropTypes.func,
  link: PropTypes.bool,
  user: PropTypes.shape({
    fullname: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    logo: PropTypes.shape({
      tiny: PropTypes.shape({
        source: PropTypes.string.isRequired,
      }).isRequired,
    }),
    type: PropTypes.oneOf(['INDIVIDUAL', 'COMPANY']).isRequired,
    primary_address: PropTypes.shape({
      company: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

UserBar.defaultProps = {
  handleClick: null,
  link: null,
};

export default UserBar;
