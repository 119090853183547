import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PackageContext = createContext();

function PackageContextProvider({ children }) {
  const [packages] = useState(window.preload.packages || []);

  return (
    <PackageContext.Provider value={{ packages }}>
      {children}
    </PackageContext.Provider>
  );
}

PackageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

PackageContextProvider.defaultProps = {};

export default PackageContextProvider;
