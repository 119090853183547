import React from 'react';
import PropTypes from 'prop-types';

function MdcLayoutGrid({ children }) {
  return <div className="mdc-layout-grid">{children}</div>;
}

MdcLayoutGrid.propTypes = {
  children: PropTypes.node.isRequired,
};

MdcLayoutGrid.defaultProps = {};

export default MdcLayoutGrid;
