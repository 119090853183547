import React, { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

export const TagContext = createContext();

function TagContextProvider({ children }) {
  const [tags] = useState(window.preload.tags || []);

  const masterTags = useMemo(() => {
    return tags.filter((tag) => !tag.parent_id);
  }, [tags]);

  const subTags = useCallback(
    (tagId) => {
      return tags
        .filter((tag) => tag.parent_id)
        .filter((tag) => tag.parent_id == tagId);
    },
    [tags],
  );

  return (
    <TagContext.Provider value={{ tags, masterTags, subTags }}>
      {children}
    </TagContext.Provider>
  );
}

TagContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

TagContextProvider.defaultProps = {};

export default TagContextProvider;
