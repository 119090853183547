import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useContext,
} from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { MDCTopAppBar } from '@material/top-app-bar';

import MdcButton from 'mdc/MdcButton';
import MdcMenu from 'mdc/MdcMenu';

import UserBar from 'fm/UserBar';
import AnnoncesMenu from 'fm/AnnoncesMenu';

import useDialog from 'hooks/useDialog';
import useAuth from 'hooks/useAuth';
import useLoader from 'hooks/useLoader';
import useRoutes from 'hooks/useRoutes';

import { UnreadContext } from 'contexts/UnreadContext';

import elipse from 'assets/svg/elipse.svg';
import logo from 'assets/svg/logo.svg';

import profileIcon from 'assets/svg/profile.svg';
import settingsIcon from 'assets/svg/settings.svg';
import addIcon from 'assets/svg/add.svg';
import logoutIcon from 'assets/svg/logout.svg';

const MAIL_ICON = 'mail';

const MENU_TEXTS = {
  mine: 'Moje epůjčovna.cz',
  profile: 'Moje předměty',
  settings: 'Nastavení',
  bidding: 'Půjčuji',
  add: 'Přidat nový předmět',
  logout: 'Odhlásit se',
};

const MENU_ICONS = {
  profile: profileIcon,
  settings: settingsIcon,
  add: addIcon,
  logout: logoutIcon,
};

const MENU_ICON = 'menu';

function Header({ scrollTarget, handleMenuClick }) {
  const history = useHistory();

  const headerRef = useRef();
  const headerComponent = useRef();

  const { route } = useRoutes();

  const { loading } = useLoader();

  const { showLogin, showRegister } = useDialog();

  const { token, user, logout } = useAuth();

  const [authMenuOpened, setAuthMenuOpened] = useState(false);

  const [annoncesMenuOpened, setAnnoncesMenuOpened] = useState(false);

  const toggleAnnoncesMenu = useCallback(() => {
    setAnnoncesMenuOpened(!annoncesMenuOpened);
  }, [annoncesMenuOpened, setAnnoncesMenuOpened]);

  const closeAnnoncesMenu = useCallback(() => {
    setAnnoncesMenuOpened(false);
  }, [setAnnoncesMenuOpened]);

  const { unread: unreadCount } = useContext(UnreadContext);

  const openAuthMenu = useCallback(() => {
    setAuthMenuOpened(true);
  }, [setAuthMenuOpened]);

  const closeAuthMenu = useCallback(() => {
    setAuthMenuOpened(false);
  }, [setAuthMenuOpened]);

  const goToMessages = useCallback(() => {
    history.push(route('profile.messages'));
  }, [history, route]);

  const goToMineAnnonces = useCallback(() => {
    history.push(route('profile.annonces'));
  }, [history, route]);

  const goToProfile = useCallback(() => {
    history.push(route('profile.edit'));
  }, [history, route]);

  const goToAnnoncesCreate = useCallback(() => {
    history.push(route('annonces.create'));
  }, [history, route]);

  useEffect(() => {
    headerComponent.current = new MDCTopAppBar(headerRef.current);

    headerComponent.current.setScrollTarget(scrollTarget.current);

    headerComponent.current.listen('MDCTopAppBar:nav', () => {
      handleMenuClick();
    });

    return () => {
      headerComponent.current.destroy();
    };
  }, [scrollTarget, handleMenuClick]);

  /* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
  return (
    <header className="mdc-top-app-bar" ref={headerRef}>
      <div className="mdc-top-app-bar__row">
        <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
          <button
            className="material-icons mdc-top-app-bar__navigation-icon mdc-icon-button"
            type="button"
          >
            {MENU_ICON}
          </button>
          <div className="mdc-top-app-bar__title">
            <Link to="/">
              <img
                alt="ePujcovna.cz"
                className="mdc-top-app-bar__logo"
                src={logo}
                title="ePujcovna.cz"
              />
            </Link>
          </div>
        </section>
        <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-end mdc-theme-var--on-primary">
          <div className="mdc-top-app-bar__links">
            <MdcButton
              handleClick={toggleAnnoncesMenu}
              label="Sortiment"
              trailingIcon="arrow_drop_down"
            />
            <AnnoncesMenu
              handleClose={closeAnnoncesMenu}
              opened={annoncesMenuOpened}
            />
            {/*<Link to={route('for_bidders')}>
              <MdcButton label="Pro inzerenty" />
            </Link>*/}
            <Link to={route('how_it_works')}>
              <MdcButton label="Jak to funguje" />
            </Link>
            <Link to={route('pricing')}>
              <MdcButton label="Ceník" />
            </Link>
            <Link to={route('annonces.create')}>
              <MdcButton label="Nabídnout předmět" />
            </Link>
          </div>
          <div
            className={[
              'mdc-top-app-bar__auth',
              token && 'mdc-top-app-bar__auth--authed',
            ]
              .filter((el) => el)
              .join(' ')}
          >
            {!token && [
              <MdcButton
                big
                handleClick={showRegister}
                key={0}
                label="Registrovat"
                outlined
              />,
              <MdcButton
                big
                handleClick={showLogin}
                key={1}
                label="Přihlásit"
                secondary
                unelevated
              />,
            ]}
            {token && (
              <div
                className="mdc-top-app-bar__messages"
                onClick={goToMessages}
                onKeyDown={goToMessages}
                role="button"
                tabIndex="0"
              >
                <div className="material-icons">{MAIL_ICON}</div>
                {unreadCount > 0 && (
                  <img
                    alt="Nepřečtené zprávy"
                    className="mdc-top-app-bar__unread-icon"
                    src={elipse}
                  />
                )}
              </div>
            )}
            {token && (
              <div className="mdc-menu-surface--anchor">
                <UserBar handleClick={openAuthMenu} user={user} />
                <MdcMenu
                  classes={['Header__menu']}
                  handleClose={closeAuthMenu}
                  open={authMenuOpened}
                >
                  <div className="mdc-list-item__label">{MENU_TEXTS.mine}</div>
                  <li
                    className="mdc-list-item"
                    onClick={goToMineAnnonces}
                    onKeyDown={goToMineAnnonces}
                    role="menuitem"
                  >
                    <span className="mdc-list-item__graphic">
                      <img src={MENU_ICONS.profile} alt={MENU_TEXTS.profile} />
                    </span>
                    <span className="mdc-list-item__text">
                      {MENU_TEXTS.profile}
                    </span>
                  </li>
                  <li
                    className="mdc-list-item"
                    onClick={goToProfile}
                    onKeyDown={goToProfile}
                    role="menuitem"
                  >
                    <span className="mdc-list-item__graphic">
                      <img
                        src={MENU_ICONS.settings}
                        alt={MENU_TEXTS.settings}
                      />
                    </span>
                    <span className="mdc-list-item__text">
                      {MENU_TEXTS.settings}
                    </span>
                  </li>
                  <div className="mdc-list-item__label">
                    {MENU_TEXTS.bidding}
                  </div>
                  <li
                    className="mdc-list-item"
                    onClick={goToAnnoncesCreate}
                    onKeyDown={goToAnnoncesCreate}
                    role="menuitem"
                  >
                    <span className="mdc-list-item__graphic">
                      <img src={MENU_ICONS.add} alt={MENU_TEXTS.add} />
                    </span>
                    <span className="mdc-list-item__text">
                      {MENU_TEXTS.add}
                    </span>
                  </li>
                  <hr />
                  <li
                    className="mdc-list-item"
                    onClick={logout}
                    onKeyDown={logout}
                    role="menuitem"
                  >
                    <span className="mdc-list-item__graphic">
                      <img src={MENU_ICONS.logout} alt={MENU_TEXTS.logout} />
                    </span>
                    <span className="mdc-list-item__text">
                      {MENU_TEXTS.logout}
                    </span>
                  </li>
                </MdcMenu>
              </div>
            )}
          </div>
        </section>
      </div>
      <div
        aria-label="Načítání"
        className={[
          'mdc-linear-progress mdc-linear-progress--indeterminate',
          loading && 'mdc-linear-progress--loading',
        ]
          .filter((el) => el)
          .join(' ')}
        role="progressbar"
      >
        <div className="mdc-linear-progress__buffering-dots" />
        <div className="mdc-linear-progress__buffer" />
        <div className="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
          <span className="mdc-linear-progress__bar-inner" />
        </div>
        <div className="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
          <span className="mdc-linear-progress__bar-inner" />
        </div>
      </div>
    </header>
  );
  /* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */
}

Header.propTypes = {
  handleMenuClick: PropTypes.func.isRequired,
  scrollTarget: PropTypes.shape({
    current: PropTypes.node,
  }).isRequired,
};

Header.defaultProps = {};

export default Header;
