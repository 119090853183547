import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Scroller() {
  const { pathname } = useLocation();

  useEffect(() => {
    const el = document.querySelector('.mdc-drawer__main');

    window.scrollTo(0, 0);

    if (el) {
      el.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}

export default Scroller;
