import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import MdcDialog from 'mdc/MdcDialog';
import MdcTextInput from 'mdc/MdcTextInput';
import MdcButton from 'mdc/MdcButton';
import MdcSelect from 'mdc/MdcSelect';
import MdcLayoutGridInner from 'mdc/MdcLayoutGridInner';
import MdcLayoutGridCell from 'mdc/MdcLayoutGridCell';

import { ADDRESSES_STORE_REQUEST } from 'routes';

import { CountryContext } from 'contexts/CountryContext';

import useAddresses from 'hooks/useAddresses';
import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';
import useSnack from 'hooks/useSnack';

function AddressDialog({ content }) {
  const formRef = useRef();

  const { refresh } = useAddresses();

  const { loading, setLoading } = useLoader();

  const { user } = useAuth();

  const { countries } = useContext(CountryContext);

  const { request, errorsFor } = useApi();

  const { info } = useSnack();

  const { close } = useDialog();

  const countryOptions = useMemo(() => {
    return countries.map((country) => {
      return {
        value: String(country.id),
        label: country.name,
      };
    });
  }, [countries]);

  const typeOptions = useMemo(() => {
    return [
      { value: '2', label: 'Kontaktní' },
      { value: '3', label: 'Doručovací' },
    ];
  }, []);

  //

  const [country, setCountry] = useState('');

  const [type, setType] = useState(content.type || '');

  //

  useEffect(() => {
    if (!user) {
      close();
    }
  }, [user, close]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);

      const data = new FormData(formRef.current);

      data.append('country_id', country);

      data.append('address_type_id', type);

      request(ADDRESSES_STORE_REQUEST, data)
        .then(
          (response) => {
            info('Adresa uložena');
            refresh();
            close();
            if (content.onSuccess) {
              content.onSuccess(String(response.json.data.key));
            }
          },
          () => {},
        )
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, request, info, close, refresh, country, type, content],
  );

  return (
    <MdcDialog title="Nová adresa">
      <form onSubmit={handleSubmit} ref={formRef}>
        <MdcLayoutGridInner>
          {content.show_address_type && (
            <MdcLayoutGridCell span={12}>
              <MdcSelect
                errors={errorsFor('address_type_id')}
                fullwidth
                handleChange={setType}
                label="Typ"
                options={typeOptions}
                required
                value={type}
              />
            </MdcLayoutGridCell>
          )}
          {content.show_first_name && user.type === 'INDIVIDUAL' && (
            <>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsFor('first_name')}
                  full
                  label="Jméno"
                  name="first_name"
                  outlined
                  required
                />
              </MdcLayoutGridCell>

              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsFor('last_name')}
                  full
                  label="Příjmení"
                  name="last_name"
                  outlined
                  required
                />
              </MdcLayoutGridCell>
            </>
          )}

          {content.show_email && (
            <MdcLayoutGridCell span={12}>
              <MdcTextInput
                errors={errorsFor('email')}
                full
                label="E-mail"
                name="email"
                outlined
              />
            </MdcLayoutGridCell>
          )}

          {content.show_phone && (
            <MdcLayoutGridCell span={12}>
              <MdcTextInput
                errors={errorsFor('phone')}
                full
                label="Telefon"
                name="phone"
                outlined
              />
            </MdcLayoutGridCell>
          )}

          {user.type === 'COMPANY' && (
            <MdcLayoutGridCell span={12}>
              <MdcTextInput
                errors={errorsFor('company')}
                full
                label="Název společnosti / půjčovny"
                name="company"
                outlined
                required
              />
            </MdcLayoutGridCell>
          )}
          {user.type === 'COMPANY' && (
            <MdcLayoutGridCell span={12}>
              <MdcTextInput
                errors={errorsFor('identification')}
                full
                label="IČO"
                name="identification"
                outlined
                required
              />
            </MdcLayoutGridCell>
          )}
          {user.type === 'COMPANY' && (
            <MdcLayoutGridCell span={12}>
              <MdcTextInput
                errors={errorsFor('identification_number')}
                full
                label="DIČ"
                name="identification_number"
                outlined
              />
            </MdcLayoutGridCell>
          )}
          {user.type === 'COMPANY' && (
            <MdcLayoutGridCell span={12}>
              <MdcTextInput
                errors={errorsFor('contact_person')}
                full
                label="Kontaktní osoba"
                name="contact_person"
                outlined
                required
              />
            </MdcLayoutGridCell>
          )}
          <MdcLayoutGridCell span={12}>
            <MdcTextInput
              errors={errorsFor('address')}
              full
              label="Adresa"
              name="address"
              outlined
              required
            />
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcTextInput
              errors={errorsFor('address2')}
              full
              label="Adresa 2"
              name="address2"
              outlined
            />
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcTextInput
              errors={errorsFor('zip')}
              full
              label="PSČ"
              name="zip"
              outlined
              required
            />
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcTextInput
              errors={errorsFor('city')}
              full
              label="Město / Obec"
              name="city"
              outlined
              required
            />
          </MdcLayoutGridCell>
          {content.show_country && (
            <MdcLayoutGridCell span={12}>
              <MdcSelect
                errors={errorsFor('country_id')}
                fullwidth
                handleChange={setCountry}
                label="Země"
                options={countryOptions}
                required
                value={country}
              />
            </MdcLayoutGridCell>
          )}
          <MdcLayoutGridCell span={12}>
            <MdcButton
              disabled={loading}
              fullwidth
              label="Odeslat"
              secondary
              type="submit"
              unelevated
            />
          </MdcLayoutGridCell>
        </MdcLayoutGridInner>
      </form>
    </MdcDialog>
  );
}

AddressDialog.propTypes = {
  content: PropTypes.shape().isRequired,
};

export default AddressDialog;
