import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const CountryContext = createContext();

function CountryContextProvider({ children }) {
  const [countries] = useState(window.preload.countries || []);

  return (
    <CountryContext.Provider value={{ countries }}>
      {children}
    </CountryContext.Provider>
  );
}

CountryContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

CountryContextProvider.defaultProps = {};

export default CountryContextProvider;
