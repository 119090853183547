import React, {
  createContext,
  useReducer,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { ADDRESSES_INDEX_REQUEST } from '../api/routes';

import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';

export const AddressContext = createContext();

export const INIT = Symbol();
export const SET_ADDRESSES = Symbol();
export const SET_PRIMARY = Symbol();

function init() {
  return { data: [], primary: null };
}

function reducer(state, action) {
  switch (action.type) {
    case SET_PRIMARY:
      return { ...state, primary: { ...action.data } };
    case SET_ADDRESSES:
      return { ...state, data: [...action.data] };
    case INIT:
      return init();
    default:
      throw new Error();
  }
}

function AddressContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, null, init);

  const { request } = useApi();

  const { token } = useAuth();

  const refresh = useCallback(() => {
    request(ADDRESSES_INDEX_REQUEST).then(
      (response) => {
        dispatch({ type: SET_ADDRESSES, data: response.json.data });
        dispatch({ type: SET_PRIMARY, data: response.json.primary_address });
      },
      () => {},
    );
  }, [request]);

  useEffect(() => {
    if (token) {
      refresh();
    } else {
      dispatch({ type: INIT });
    }
  }, [token, refresh]);

  const primaryAddress = useMemo(() => {
    return state.primary;
  }, [state.primary]);

  const addresses = useMemo(() => {
    return state.data;
  }, [state.data]);

  return (
    <AddressContext.Provider
      value={{ state, dispatch, refresh, primaryAddress, addresses }}
    >
      {children}
    </AddressContext.Provider>
  );
}

AddressContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AddressContextProvider.defaultProps = {};

export default AddressContextProvider;
