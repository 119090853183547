import React from 'react';
import { Link } from 'react-router-dom';

import Panel from './Panel';
import MdcLayoutGridInner from 'mdc/MdcLayoutGridInner';
import MdcLayoutGridCell from 'mdc/MdcLayoutGridCell';

import useTags from 'hooks/useTags';
import useRoutes from 'hooks/useRoutes';
import useAuth from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';

import logo from 'assets/svg/logo.svg';

const LOGIN = 'Přihlásit se';
const REGISTER = 'Registrovat se';

const COPY = '© Copyright 2021 epujcovna.cz';

const LINKS = {
  sortiment: {
    title: 'Sortiment',
  },
  rent: {
    title: 'Půjčovna',
    links: [
      /*{
        title: 'Pro inzerenty',
        route: 'for_bidders',
      },*/
      {
        title: 'Jak to funguje',
        route: 'how_it_works',
      },
      {
        title: 'Ceník',
        route: 'pricing',
      },
    ],
  },
  about: {
    title: 'O nás',
    links: [
      {
        title: 'Časté dotazy',
        route: 'faq',
      },
      {
        title: 'Kontakt',
        route: 'contact',
      },
    ],
  },
};

function Footer() {
  const { token } = useAuth();

  const { showLogin, showRegister } = useDialog();

  const { masterTags } = useTags();

  const { route } = useRoutes();

  return (
    <footer className="mdc-footer mdc-typography--center-tablet">
      <Panel theme="primary">
        <nav>
          <MdcLayoutGridInner>
            <MdcLayoutGridCell desktop={3} phone={4} tablet={4}>
              <Link to="/">
                <img
                  alt="Floormax"
                  className="mdc-footer__logo"
                  src={logo}
                  style={{ width: '100%', maxWidth: '250px' }}
                />
              </Link>
              <div className="mdc-footer__by">{COPY}</div>
            </MdcLayoutGridCell>
            <MdcLayoutGridCell desktop={3} phone={4} tablet={4}>
              <div className="mdc-typography--headline4">
                {LINKS.sortiment.title}
              </div>
              <ul className="mdc-footer__links">
                {masterTags.map((tag) => (
                  <li key={tag.id}>
                    <Link
                      to={{
                        pathname: route('annonces.index'),
                        state: { masterTag: String(tag.id) },
                      }}
                    >
                      <span className="mdc-footer__link">{tag.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </MdcLayoutGridCell>
            <MdcLayoutGridCell desktop={3} phone={4} tablet={4}>
              <div className="mdc-typography--headline4">
                {LINKS.rent.title}
              </div>
              <ul className="mdc-footer__links">
                {LINKS.rent.links.map((link) => (
                  <li key={link.title}>
                    <Link to={route(link.route)}>
                      <span className="mdc-footer__link">{link.title}</span>
                    </Link>
                  </li>
                ))}
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/obchodni-podminky.pdf"
                  >
                    <span className="mdc-footer__link">Podmínky</span>
                  </a>
                </li>
                {!token && [
                  <li className="mdc-footer__link" key={0}>
                    <span
                      onClick={showRegister}
                      onKeyDown={showRegister}
                      role="button"
                      tabIndex="0"
                    >
                      {REGISTER}
                    </span>
                  </li>,
                  <li className="mdc-footer__link" key={1}>
                    <span
                      onClick={showLogin}
                      onKeyDown={showLogin}
                      role="button"
                      tabIndex="0"
                    >
                      {LOGIN}
                    </span>
                  </li>,
                ]}
              </ul>
            </MdcLayoutGridCell>
            <MdcLayoutGridCell desktop={3} phone={4} tablet={4}>
              <div className="mdc-typography--headline4">
                {LINKS.about.title}
              </div>
              <ul className="mdc-footer__links">
                {LINKS.about.links.map((link) => (
                  <li key={link.title}>
                    <Link to={route(link.route)}>
                      <span className="mdc-footer__link">{link.title}</span>
                    </Link>
                  </li>
                ))}
                <li>
                  <a href="https://www.facebook.com/epujcovnacz">
                    <img
                      src="/img/ico-fb.png"
                      width="16"
                      alt="Facebook icon"
                    ></img>
                    &nbsp;
                    <span className="mdc-footer__link">Facebook</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/epujcovnacz">
                    <img
                      src="/img/ico-insta.png"
                      width="16"
                      alt="Instagram icon"
                    ></img>
                    &nbsp;
                    <span className="mdc-footer__link">Instagram</span>
                  </a>
                </li>
              </ul>
            </MdcLayoutGridCell>
          </MdcLayoutGridInner>
        </nav>
      </Panel>
    </footer>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
