import { API_URL, REQUEST_URLS, X_API_KEY } from 'routes';

export function shouldRefresh(requested, timeToRefresh) {
  if (!timeToRefresh) {
    throw new Error();
  }

  return (
    requested === null ||
    requested === undefined ||
    Date.now() - Number(requested) > Number(timeToRefresh)
  );
}

export function request(
  signal,
  url,
  method,
  locale,
  auth = null,
  data = null,
  params = null,
) {
  if (!signal || !url || !method || !locale) {
    throw new Error();
  }

  const headers = {
    'Accept-Language': locale,
    Accept: 'application/json',
    'X-API-Key': X_API_KEY,
  };

  let body = null;
  let searchParams = null;

  if (method !== 'GET') {
    if (data instanceof FormData) {
      body = data;
    } else if (data instanceof HTMLElement) {
      body = new FormData(data);
    } else if (typeof data === 'string') {
      body = data;
      headers['Content-Type'] = 'application/json';
    } else {
      body = JSON.stringify(data);
      headers['Content-Type'] = 'application/json';
    }
  } else {
    if (data) {
      if (data instanceof FormData) {
        searchParams = new URLSearchParams(data);
      } else if (data instanceof HTMLElement) {
        searchParams = new URLSearchParams(new FormData(data));
      } else if (typeof data === 'string') {
        searchParams = new URLSearchParams(data);
      } else {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (data[key] instanceof Array) {
            formData.append(key + '[]', data[key]);
          } else if (typeof data[key] === 'string') {
            formData.append(key, data[key]);
          }
        });

        searchParams = new URLSearchParams(formData);
      }
    }
  }

  if (auth) {
    headers.Authorization = `Bearer ${auth}`;
  }

  if (params) {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        if (
          typeof params[key] === 'string' ||
          typeof params[key] === 'number'
        ) {
          url = url.replace(`:${key}`, String(params[key]));
        }
      }
    }
  }

  return fetch(url + (searchParams ? '?' + searchParams.toString() : ''), {
    method,
    headers,
    body,
    signal,
  }).then(
    (response) => {
      const status = {
        ok: response.ok,
        status: response.status,
        text: response.statusText,
      };

      return response.json().then(
        (json) => {
          return { status, json };
        },
        () => {
          status.status = 204;

          return { status, json: null };
        },
      );
    },
    (error) => {
      throw error;
    },
  );
}

export default function requestRoute(
  signal,
  route,
  locale,
  auth,
  data,
  params,
) {
  if (!(route in REQUEST_URLS)) {
    throw new Error();
  }

  return request(
    signal,
    API_URL + REQUEST_URLS[route].url,
    REQUEST_URLS[route].method,
    locale,
    auth,
    data,
    params,
  );
}
