import React from 'react';
import PropTypes from 'prop-types';

import MdcDialog from 'mdc/MdcDialog';
import MdcButton from 'mdc/MdcButton';

import checkIcon from 'assets/svg/check_success.svg';

function SuccessDialog({ content }) {
  return (
    <MdcDialog>
      <div className="mdc-dialog--success">
        <div className="mdc-dialog--success__icon">
          <img alt="Úspěch" src={checkIcon} />
        </div>
        <div className="mdc-dialog--success__content">{content}</div>
        <div>
          <MdcButton dialogClose label="OK" success unelevated />
        </div>
      </div>
    </MdcDialog>
  );
}

SuccessDialog.propTypes = {
  content: PropTypes.string.isRequired,
};

SuccessDialog.defaultProps = {};

export default SuccessDialog;
