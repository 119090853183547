import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-day-picker/lib/style.css';
import '../sass/index.scss';

import App from './App';

import AddressContextProvider from 'contexts/AddressContext';
import RouteContextProvider from 'contexts/RouteContext';
import AnnonceContextProvider from 'contexts/AnnonceContext';
import AuthContextProvider from 'contexts/AuthContext';
import LocaleContextProvider from 'contexts/LocaleContext';
import TagContextProvider from 'contexts/TagContext';
import CountryContextProvider from 'contexts/CountryContext';
import DialogContextProvider from 'contexts/DialogContext';
import PackageContextProvider from 'contexts/PackageContext';
import LoadingContextProvider from 'contexts/LoadingContext';
import SnackContextProvider from 'contexts/SnackContext';
import UnreadContextProvider from 'contexts/UnreadContext';

ReactDOM.render(
  <React.StrictMode>
    <SnackContextProvider>
      <Router>
        <LoadingContextProvider>
          <LocaleContextProvider>
            <RouteContextProvider>
              <AuthContextProvider>
                <UnreadContextProvider>
                  <AddressContextProvider>
                    <TagContextProvider>
                      <CountryContextProvider>
                        <DialogContextProvider>
                          <PackageContextProvider>
                            <AnnonceContextProvider>
                              <App />
                            </AnnonceContextProvider>
                          </PackageContextProvider>
                        </DialogContextProvider>
                      </CountryContextProvider>
                    </TagContextProvider>
                  </AddressContextProvider>
                </UnreadContextProvider>
              </AuthContextProvider>
            </RouteContextProvider>
          </LocaleContextProvider>
        </LoadingContextProvider>
      </Router>
    </SnackContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
