import React, { useContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { MDCDialog } from '@material/dialog';

import { INIT, DialogContext } from '../../contexts/DialogContext';

const CLOSE_ICON = 'close';

function MdcDialog({ title, children, dense, inputable }) {
  const dialogRef = useRef();
  const dialogComponent = useRef();

  const { dispatch: dispatchDialog } = useContext(DialogContext);

  const closeDialog = useCallback(() => {
    dialogComponent.current.close();
  }, []);

  useEffect(() => {
    dialogComponent.current = new MDCDialog(dialogRef.current);
    dialogComponent.current.open();

    dialogComponent.current.listen('MDCDialog:closed', () => {
      dispatchDialog({ type: INIT });
    });

    return () => {
      dialogComponent.current.destroy();
    };
  }, [dispatchDialog]);

  return (
    <div
      className={[
        'mdc-dialog',
        dense && 'mdc-dialog--dense',
        inputable && 'mdc-dialog--inputable',
      ]
        .filter((el) => el)
        .join(' ')}
      ref={dialogRef}
    >
      <div className="mdc-dialog__container">
        <div
          aria-modal="true"
          className="mdc-dialog__surface"
          role="alertdialog"
        >
          {title && <h2 className="mdc-dialog__title">{title}</h2>}
          <div className="mdc-dialog__content">{children}</div>
          <span
            className="material-icons mdc-dialog__close"
            onClick={closeDialog}
            onKeyDown={closeDialog}
            role="button"
            tabIndex="-1"
          >
            {CLOSE_ICON}
          </span>
        </div>
      </div>
      <div className="mdc-dialog__scrim" />
    </div>
  );
}

MdcDialog.propTypes = {
  children: PropTypes.node.isRequired,
  dense: PropTypes.bool,
  inputable: PropTypes.bool,
  title: PropTypes.string,
};

MdcDialog.defaultProps = {
  dense: null,
  inputable: null,
  title: null,
};

export default MdcDialog;
