import React, { useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { MDCDrawer } from '@material/drawer';

import MdcButton from 'mdc/MdcButton';

import Header from 'fm/Header';
import Footer from 'fm/Footer';

import useRoutes from 'hooks/useRoutes';
import useAuth from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';

const LINKS = [
  'annonces.index',
  /*'for_bidders',*/
  'how_it_works',
  'pricing',
  'annonces.create',
];

const MENU_TEXT = 'Menu';

function MdcDrawer({ children }) {
  const drawerRef = useRef();
  const drawerComponent = useRef();
  const mainRef = useRef();

  const { token } = useAuth();

  const { route, label } = useRoutes();

  const { showLogin, showRegister } = useDialog();

  useEffect(() => {
    drawerComponent.current = new MDCDrawer(drawerRef.current);

    return () => {
      drawerComponent.current.destroy();
    };
  }, []);

  const handleMenuClick = useCallback(() => {
    if (drawerComponent.current) {
      drawerComponent.current.open = !drawerComponent.current.open;
    }
  }, []);

  const close = useCallback(() => {
    if (drawerComponent.current) {
      drawerComponent.current.open = false;
    }
  }, []);

  const closeShowLogin = useCallback(() => {
    close();
    showLogin();
  }, [close, showLogin]);

  const closeShowRegister = useCallback(() => {
    close();
    showRegister();
  }, [close, showRegister]);

  /* eslint-disable react/forbid-component-props */
  return [
    <aside
      className="mdc-drawer mdc-drawer--dismissible"
      key={0}
      ref={drawerRef}
    >
      <div className="mdc-top-app-bar__row">
        <div className="mdc-top-app-bar__section mdc-typography--headline4">
          {MENU_TEXT}
        </div>
      </div>
      <div className="mdc-drawer__content">
        <nav className="mdc-list">
          {LINKS.map((link) => {
            return (
              <Link
                className="mdc-list-item"
                key={link}
                onClick={close}
                to={route(link)}
              >
                <span className="mdc-list-item__text">{label(link)}</span>
              </Link>
            );
          })}
          {!token && (
            <div className="mdc-drawer__buttons">
              <MdcButton
                big
                fullwidth
                handleClick={closeShowRegister}
                label="Registrovat"
                outlined
              />
              <MdcButton
                big
                fullwidth
                handleClick={closeShowLogin}
                label="Přihlásit"
                secondary
                unelevated
              />
            </div>
          )}
        </nav>
      </div>
    </aside>,
    <div className="mdc-drawer-app-content" key={1}>
      <Header handleMenuClick={handleMenuClick} scrollTarget={mainRef} />
      <main className="mdc-drawer__main" ref={mainRef}>
        <div className="mdc-top-app-bar--fixed-adjust">
          {children}
          <Footer />
        </div>
      </main>
    </div>,
  ];
  /* eslint-enable react/forbid-component-props */
}

MdcDrawer.propTypes = {
  children: PropTypes.node.isRequired,
};

MdcDrawer.defaultProps = {};

export default MdcDrawer;
