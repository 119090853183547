import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { MDCSelect } from '@material/select';

function Component({
  handleChange,
  options,
  label,
  required,
  fullwidth,
  surface,
  value,
  nolabel,
  errors,
}) {
  const selectRef = useRef();
  const selectComponent = useRef();

  useEffect(() => {
    selectComponent.current = new MDCSelect(selectRef.current);

    selectComponent.current.listen('MDCSelect:change', () => {
      if (required && !selectComponent.current.value) {
        return;
      }

      handleChange(selectComponent.current.value);
    });

    return () => {
      selectComponent.current.destroy();
    };
  }, [options, handleChange, required]);

  useEffect(() => {
    if (selectComponent.current.value != value) {
      selectComponent.current.value = value;
    }
  }, [value, options, handleChange]);

  return [
    <div
      className={[
        'mdc-select mdc-select--outlined',
        required && 'mdc-select--required',
        fullwidth && 'mdc-select--fullwidth',
        surface && 'mdc-select--surface',
        nolabel && 'mdc-select--no-label',
      ]
        .filter((el) => el)
        .join(' ')}
      key={0}
      ref={selectRef}
    >
      <div className="mdc-select__anchor">
        <i className="mdc-select__dropdown-icon" />
        <div aria-required={required} className="mdc-select__selected-text" />
        <div className="mdc-notched-outline">
          <div className="mdc-notched-outline__leading" />
          {!nolabel && label && (
            <div className="mdc-notched-outline__notch">
              <span className="mdc-floating-label">{label}</span>
            </div>
          )}
          <div className="mdc-notched-outline__trailing" />
        </div>
      </div>
      <div
        className="mdc-select__menu mdc-menu mdc-menu-surface"
        role="listbox"
      >
        <ul className="mdc-list">
          <li className="mdc-list-item mdc-list-item--selected" data-value="">
            {nolabel && label}
          </li>
          {options.map((option) => {
            return (
              <li
                className="mdc-list-item"
                data-value={option.value}
                key={option.value}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      </div>
    </div>,
    <div className="errors" key={1}>
      {errors &&
        errors.map((error) => {
          return (
            <span
              className="mdc-theme--error mdc-typography--caption"
              key={error}
            >
              {error}
              {errors.length > 1 ? ' ' : ''}
            </span>
          );
        })}
    </div>,
  ];
}

Component.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  fullwidth: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  nolabel: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  required: PropTypes.bool,
  surface: PropTypes.bool,
  value: PropTypes.string,
};

Component.defaultProps = {
  errors: null,
  fullwidth: null,
  handleChange: () => {},
  nolabel: null,
  required: null,
  surface: null,
  value: '',
};

export default Component;
