import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { MDCSnackbar } from '@material/snackbar';

import useSnack from 'hooks/useSnack';

const OK = 'Ok';

function MdcSnackBar({ content, theme }) {
  const snackRef = useRef();
  const snackComponent = useRef();

  const { pop } = useSnack();

  useEffect(() => {
    snackComponent.current = new MDCSnackbar(snackRef.current);

    snackComponent.current.open();

    snackComponent.current.listen('MDCSnackbar:closed', pop);

    return () => {
      if (snackComponent.current) {
        snackComponent.current.destroy();
      }
    };
  }, [pop]);

  return (
    <div
      className={['mdc-snackbar', `mdc-snackbar--${theme}`]
        .filter((el) => el)
        .join(' ')}
      ref={snackRef}
    >
      <div className="mdc-snackbar__surface">
        <div aria-live="polite" className="mdc-snackbar__label" role="status">
          {content}
        </div>
        <div className="mdc-snackbar__actions">
          <button className="mdc-button mdc-snackbar__action" type="button">
            <div className="mdc-button__ripple" />
            <span className="mdc-button__label">{OK}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

MdcSnackBar.propTypes = {
  content: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['info', 'success', 'error']).isRequired,
};
MdcSnackBar.defaultProps = {};

export default MdcSnackBar;
