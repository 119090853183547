import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MDCTextField } from '@material/textfield';

function MdcTextArea({
  name,
  label,
  handleChange,
  errors,
  required,
  placeholder,
  nolabel,
  surface,
  withCounter,
  max,
  full,
  value,
  handleKeyPress,
  disabled,
}) {
  const inputRef = useRef();
  const inputComponent = useRef();

  useEffect(() => {
    inputComponent.current = new MDCTextField(inputRef.current);

    return () => {
      inputComponent.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (inputComponent.current.value != value) {
      inputComponent.current.value = value;
    }
  }, [value]);

  const onChange = useCallback(
    (event) => {
      handleChange(event.target.value);
    },
    [handleChange],
  );

  return [
    <label
      className={[
        'mdc-text-field mdc-text-field--textarea',
        surface && 'mdc-text-field--surface',
        nolabel && 'mdc-text-field--no-label',
        full && 'mdc-text-field--full',
      ]
        .filter((el) => el)
        .join(' ')}
      htmlFor={name}
      key={0}
      ref={inputRef}
    >
      {withCounter && max && (
        <div className="mdc-text-field-character-counter">
          {'0 / '}
          {max}
        </div>
      )}
      <textarea
        aria-label={label}
        className="mdc-text-field__input"
        disabled={disabled}
        maxLength={max}
        name={name}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        required={required}
      />
      <div className="mdc-notched-outline">
        <div className="mdc-notched-outline__leading" />
        {!nolabel && label && (
          <div className="mdc-notched-outline__notch">
            <span className="mdc-floating-label">{label}</span>
          </div>
        )}
        <div className="mdc-notched-outline__trailing" />
      </div>
    </label>,
    <div className="errors" key={1}>
      {errors &&
        errors.map((error) => {
          return (
            <span
              className="mdc-theme--error mdc-typography--caption"
              key={error}
            >
              {error}
              {errors.length > 1 ? ' ' : ''}
            </span>
          );
        })}
    </div>,
  ];
}

MdcTextArea.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  full: PropTypes.bool,
  handleChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  nolabel: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  surface: PropTypes.bool,
  value: PropTypes.string,
};
MdcTextArea.defaultProps = {
  disabled: null,
  errors: null,
  full: null,
  handleChange: () => {},
  handleKeyPress: null,
  label: null,
  nolabel: null,
  placeholder: null,
  required: null,
  surface: null,
  value: '',
};

export default MdcTextArea;
