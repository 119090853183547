import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

function FormField({ children, label, hint, big, collapsible, errors }) {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  return (
    <div
      className={['form-field', collapsed && 'form-field--collapsed']
        .filter((el) => el)
        .join(' ')}
    >
      {(label || collapsible) && (
        <div
          className={['form-field__label', big && 'form-field__label--big']
            .filter((el) => el)
            .join(' ')}
        >
          <span>{label}</span>
          {collapsible && (
            <button
              className={[
                'form-field__collapse material-icons',
                collapsed && 'form-field__collapse--collapsed',
              ]
                .filter((el) => el)
                .join(' ')}
              onClick={toggle}
              tabIndex="-1"
              type="button"
            >
              arrow_drop_down
            </button>
          )}
        </div>
      )}
      {hint && (
        <div className="form-field__hint mdc-typography--readable">{hint}</div>
      )}
      <div className="form-field__content">{children}</div>
      {errors && errors.length > 0 && (
        <div className="errors" style={{ marginTop: '0.5rem' }}>
          {errors.map((error) => {
            return (
              <span
                className="mdc-theme--error mdc-typography--caption"
                key={error}
              >
                {error}
                {errors.length > 1 ? ' ' : ''}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
}

FormField.propTypes = {
  big: PropTypes.bool,
  children: PropTypes.node.isRequired,
  collapsible: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  hint: PropTypes.string,
  label: PropTypes.string,
};

FormField.defaultProps = {
  big: null,
  collapsible: null,
  errors: [],
  hint: null,
  label: null,
};

export default FormField;
