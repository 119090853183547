import React from 'react';
import PropTypes from 'prop-types';

import MdcDialog from 'mdc/MdcDialog';
import MdcLayoutGridInner from 'mdc/MdcLayoutGridInner';
import MdcLayoutGridCell from 'mdc/MdcLayoutGridCell';

import UserBar from 'fm/UserBar';

import goldStar from 'assets/svg/gold_star.svg';
import grayStar from 'assets/svg/grey_star.svg';

function RatesDialog({ content }) {
  return (
    <MdcDialog dense title="Hodnocení">
      <MdcLayoutGridInner>
        {content.annonce.ratings.map((rating, index) => {
          return (
            <MdcLayoutGridCell span={12} key={index}>
              <div className="mdc-annonce-show__author-stars">
                <UserBar user={rating.user} />
                <div>
                  {Array(rating.rating)
                    .fill(null)
                    .map((_, index) => {
                      return (
                        <img
                          alt="Zlatá hvězda"
                          className="mdc-annonce-card__star"
                          key={index}
                          src={goldStar}
                        />
                      );
                    })}
                  {Array(4 - rating.rating)
                    .fill(null)
                    .map((_, index) => {
                      return (
                        <img
                          alt="Žádná hvězda"
                          className="mdc-annonce-card__star"
                          key={index}
                          src={grayStar}
                        />
                      );
                    })}
                </div>
                {rating.comment && (
                  <div className="mdc-typography--body1">{rating.comment}</div>
                )}
              </div>
            </MdcLayoutGridCell>
          );
        })}
      </MdcLayoutGridInner>
    </MdcDialog>
  );
}

RatesDialog.propTypes = {
  content: PropTypes.shape().isRequired,
};

export default RatesDialog;
