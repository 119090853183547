import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { MDCRipple } from '@material/ripple';

function MdcButton({
  label,
  leadingIcon,
  trailingIcon,
  raised,
  outlined,
  unelevated,
  handleClick,
  type,
  disabled,
  fullwidth,
  secondary,
  success,
  big,
  huge,
  uppercase,
  dialogClose,
  error,
  regular,
  orange,
}) {
  const buttonRef = useRef();

  const buttonComponent = useRef();

  useEffect(() => {
    buttonComponent.current = new MDCRipple(buttonRef.current);

    return () => {
      buttonComponent.current.destroy();
    };
  }, []);

  /* eslint-disable react/button-has-type */
  return (
    <button
      className={[
        'mdc-button',
        raised && 'mdc-button--raised',
        outlined && 'mdc-button--outlined',
        unelevated && 'mdc-button--unelevated',
        fullwidth && 'mdc-button--fullwidth',
        secondary && 'mdc-theme-var--secondary-on',
        success && 'mdc-theme-var--success-on',
        error && 'mdc-theme-var--error-on',
        big && 'mdc-button--big',
        huge && 'mdc-button--huge',
        regular && 'mdc-theme-var--disabled-on',
        orange && 'mdc-button--orange',
      ]
        .filter((el) => el)
        .join(' ')}
      data-mdc-dialog-action={dialogClose ? 'close' : null}
      disabled={disabled}
      onClick={handleClick}
      ref={buttonRef}
      type={type}
    >
      <div className="mdc-button__ripple" />
      {leadingIcon && (
        <i aria-hidden="true" className="material-icons mdc-button__icon">
          {leadingIcon}
        </i>
      )}
      <span
        className={[
          'mdc-button__label',
          uppercase && 'mdc-typography--uppercase',
        ]
          .filter((el) => el)
          .join(' ')}
      >
        {label}
      </span>
      {trailingIcon && (
        <i aria-hidden="true" className="material-icons mdc-button__icon">
          {trailingIcon}
        </i>
      )}
    </button>
  );
  /* eslint-enable react/button-has-type */
}

MdcButton.propTypes = {
  big: PropTypes.bool,
  dialogClose: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullwidth: PropTypes.bool,
  handleClick: PropTypes.func,
  huge: PropTypes.bool,
  label: PropTypes.string.isRequired,
  leadingIcon: PropTypes.string,
  orange: PropTypes.string,
  outlined: PropTypes.bool,
  raised: PropTypes.bool,
  regular: PropTypes.bool,
  secondary: PropTypes.bool,
  success: PropTypes.bool,
  trailingIcon: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  unelevated: PropTypes.bool,
  uppercase: PropTypes.bool,
};

MdcButton.defaultProps = {
  big: null,
  dialogClose: null,
  disabled: null,
  error: null,
  fullwidth: null,
  handleClick: null,
  huge: null,
  leadingIcon: null,
  orange: null,
  outlined: null,
  raised: null,
  regular: null,
  secondary: null,
  success: null,
  trailingIcon: null,
  type: 'button',
  unelevated: null,
  uppercase: null,
};

export default MdcButton;
