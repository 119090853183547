import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LocaleContext = createContext();

function LocaleContextProvider({ children }) {
  const [locale] = useState(window.preload.locale || 'cs');

  return (
    <LocaleContext.Provider value={{ locale }}>
      {children}
    </LocaleContext.Provider>
  );
}

LocaleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

LocaleContextProvider.defaultProps = {};

export default LocaleContextProvider;
