import React from 'react';
import PropTypes from 'prop-types';

function UserLogo({ user, classes, override }) {
  return (
    <div
      className={[...classes, 'mdc-user-logo'].filter((el) => el).join(' ')}
      style={{
        backgroundImage: override
          ? `url(${override}) `
          : user.logo
          ? `url(${user.logo.thumb.source}) `
          : null,
      }}
    >
      {user.logo || override ? null : (
        <div className="mdc-user-logo__text">{user.letters}</div>
      )}
    </div>
  );
}

UserLogo.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string),
  override: PropTypes.string,
  user: PropTypes.shape({
    type: PropTypes.string.isRequired,
    primary_address: PropTypes.shape({
      company: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }).isRequired,
    letters: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      thumb: PropTypes.shape({
        source: PropTypes.string,
      }),
    }),
  }).isRequired,
};
UserLogo.defaultProps = {
  classes: [],
  override: null,
};

export default UserLogo;
