import React, { useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MdcDialog from 'mdc/MdcDialog';
import MdcButton from 'mdc/MdcButton';
import MdcLayoutGridInner from 'mdc/MdcLayoutGridInner';
import MdcLayoutGridCell from 'mdc/MdcLayoutGridCell';

import { HIGHLIGHT_REQUEST, HIGHLIGHT_PRICE_REQUEST } from 'routes';

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';

const TEXTS = {
  highlight: 'Topovat předmět za',
  kc: 'Kč na',
  days: 'dní.',
  hint: 'Po kliknutí na Provést platbu budete přesměrováni na platební bránu.',
};

const HIGHLIGHT_LENGHT_IN_DAYS = 10;

function HighlightDialog({ content }) {
  const formRef = useRef();

  const { loading, setLoading } = useLoader();

  const { request } = useApi();

  const { request: requestPrice } = useApi();

  const [price, setPrice] = useState(0);

  useEffect(() => {
    setLoading(true);

    requestPrice(HIGHLIGHT_PRICE_REQUEST, null, { annonce: content.annonce.id })
      .then(
        (response) => {
          setPrice(response.json.data);
        },
        () => {},
      )
      .finally(() => {
        setLoading(false);
      });
  }, [requestPrice, setLoading, setPrice, content]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (price > 0) {
        setLoading(true);

        request(HIGHLIGHT_REQUEST, formRef.current, {
          annonce: content.annonce.id,
        })
          .then(
            (response) => {
              window.location = response.json.data.href;
              /*
              content.refresh();

              showSuccess(
                `Platba za topování vašeho předmětu proběhla v pořádku. Váš předmět bude topován ${HIGHLIGHT_LENGHT_IN_DAYS} dni.`,
              );
              */
            },
            () => {},
          )
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [setLoading, request, content, price],
  );

  return (
    <MdcDialog dense inputable title="Topovat předmět">
      <form onSubmit={handleSubmit} ref={formRef}>
        <MdcLayoutGridInner>
          <MdcLayoutGridCell span={12}>
            <div className="mdc-typography--body1">
              {TEXTS.highlight} {Number(price).toFixed(2)} {TEXTS.kc}{' '}
              {HIGHLIGHT_LENGHT_IN_DAYS} {TEXTS.days}
            </div>
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcButton
              big
              disabled={loading}
              label="Provést platbu"
              secondary
              type="submit"
              unelevated
            />
            <div style={{ marginTop: '1rem' }}>{TEXTS.hint}</div>
          </MdcLayoutGridCell>
        </MdcLayoutGridInner>
      </form>
    </MdcDialog>
  );
}

HighlightDialog.propTypes = {
  content: PropTypes.shape().isRequired,
};

export default HighlightDialog;
