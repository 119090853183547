import React from 'react';
import PropTypes from 'prop-types';

import MdcDialog from 'mdc/MdcDialog';
import MdcButton from 'mdc/MdcButton';

import closeIcon from 'assets/svg/close_error.svg';

function ErrorDialog({ content }) {
  return (
    <MdcDialog>
      <div className="mdc-dialog--success">
        <div className="mdc-dialog--success__icon">
          <img alt="Neúspěch" src={closeIcon} />
        </div>
        <div className="mdc-dialog--success__content">{content}</div>
        <div>
          <MdcButton dialogClose error label="OK" unelevated />
        </div>
      </div>
    </MdcDialog>
  );
}

ErrorDialog.propTypes = {
  content: PropTypes.string.isRequired,
};

ErrorDialog.defaultProps = {};

export default ErrorDialog;
