import React from 'react';
import PropTypes from 'prop-types';

import MdcLayoutGrid from 'mdc/MdcLayoutGrid';

function Panel({ title, children, theme, background, noTop }) {
  return (
    <section
      className={[
        'mdc-panel',
        `mdc-panel--${theme}`,
        noTop && 'mdc-panel--no-top',
        background && background.class,
      ]
        .filter((el) => el)
        .join(' ')}
      style={{
        backgroundImage: background ? `url(${background.src[0]}) ` : null,
      }}
    >
      <MdcLayoutGrid>
        {title && (
          <h2 className="mdc-panel__title mdc-typography--headline2 mdc-theme--primary">
            {title}
          </h2>
        )}
        {children}
      </MdcLayoutGrid>
    </section>
  );
}

Panel.propTypes = {
  background: PropTypes.shape({
    src: PropTypes.arrayOf(PropTypes.string).isRequired,
    class: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  noTop: PropTypes.bool,
  theme: PropTypes.oneOf(['primary', 'background', 'surface']),
  title: PropTypes.string,
};

Panel.defaultProps = {
  background: null,
  noTop: null,
  theme: 'background',
  title: null,
};

export default Panel;
