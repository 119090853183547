import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

function MdcLever({ left, right, children, handleChange }) {
  const [selected, setSelected] = useState(0);

  const setLeft = useCallback(() => {
    if (selected !== 0) {
      setSelected(0);
    }
  }, [setSelected, selected]);

  const setRight = useCallback(() => {
    if (selected !== 1) {
      setSelected(1);
    }
  }, [selected, setSelected]);

  const toggle = useCallback(() => {
    setSelected((selected + 1) % 2);
  }, [selected, setSelected]);

  useEffect(() => {
    if (handleChange) {
      handleChange(selected);
    }
  }, [selected, handleChange]);

  return (
    <div className="mdc-lever">
      <div className="mdc-lever__header">
        <span
          className={[
            'mdc-lever__option',
            selected === 0 && 'mdc-lever__option--selected',
          ]
            .filter((el) => el)
            .join(' ')}
          onClick={setLeft}
          onKeyDown={setLeft}
          role="button"
          tabIndex="-1"
        >
          {left}
        </span>
        <span
          className="mdc-lever__lever"
          onClick={toggle}
          onKeyDown={toggle}
          role="button"
          tabIndex="-1"
        >
          <span
            className={[
              'mdc-lever__button',
              selected === 1 && 'mdc-lever__button--on',
            ]
              .filter((el) => el)
              .join(' ')}
          />
        </span>
        <span
          className={[
            'mdc-lever__option',
            selected === 1 && 'mdc-lever__option--selected',
          ]
            .filter((el) => el)
            .join(' ')}
          onClick={setRight}
          onKeyDown={setRight}
          role="button"
          tabIndex="-1"
        >
          {right}
        </span>
      </div>
      {children && (
        <div>
          {selected === 0 && children[0]}
          {selected === 1 && children[1]}
        </div>
      )}
    </div>
  );
}

MdcLever.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  handleChange: PropTypes.func,
  left: PropTypes.string.isRequired,
  right: PropTypes.string.isRequired,
};

MdcLever.defaultProps = {
  children: null,
  handleChange: null,
};

export default MdcLever;
