import React, { createContext, useMemo, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';

// Dialog components
import RegisterDialog from '../components/dialogs/RegisterDialog';
import LoginDialog from '../components/dialogs/LoginDialog';
import SuccessDialog from '../components/dialogs/SuccessDialog';
import ErrorDialog from '../components/dialogs/ErrorDialog';
import AddressDialog from '../components/dialogs/AddressDialog';
import HighlightDialog from '../components/dialogs/HighlightDialog';
import RateDialog from '../components/dialogs/RateDialog';
import ForgotDialog from '../components/dialogs/ForgotDialog';
import RatesDialog from '../components/dialogs/RatesDialog';

export const DialogContext = createContext();

export const INIT = Symbol();
export const REGISTER_DIALOG = Symbol();
export const LOGIN_DIALOG = Symbol();
export const SUCCESS_DIALOG = Symbol();
export const ERROR_DIALOG = Symbol();
export const ADDRESS_DIALOG = Symbol();
export const HIGHLIGHT_DIALOG = Symbol();
export const FORGOT_DIALOG = Symbol();
export const RATE_DIALOG = Symbol();
export const RATES_DIALOG = Symbol();

const DIALOG_COMPONENTS = {
  [REGISTER_DIALOG]: RegisterDialog,
  [LOGIN_DIALOG]: LoginDialog,
  [SUCCESS_DIALOG]: SuccessDialog,
  [ERROR_DIALOG]: ErrorDialog,
  [ADDRESS_DIALOG]: AddressDialog,
  [HIGHLIGHT_DIALOG]: HighlightDialog,
  [FORGOT_DIALOG]: ForgotDialog,
  [RATE_DIALOG]: RateDialog,
  [RATES_DIALOG]: RatesDialog,
};

function init() {
  return { type: null, key: null, content: null };
}

function reducer(_, action) {
  switch (action.type) {
    case REGISTER_DIALOG:
    case LOGIN_DIALOG:
    case SUCCESS_DIALOG:
    case ERROR_DIALOG:
    case ADDRESS_DIALOG:
    case HIGHLIGHT_DIALOG:
    case FORGOT_DIALOG:
    case RATE_DIALOG:
    case RATES_DIALOG:
      return {
        type: action.type,
        key: performance.now(),
        content: action.content,
      };
    case INIT:
      return init();
    default:
      throw new Error();
  }
}

function DialogContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, null, init);

  const showLogin = useCallback(() => {
    dispatch({ type: LOGIN_DIALOG });
  }, []);

  const showRegister = useCallback(() => {
    dispatch({ type: REGISTER_DIALOG });
  }, []);

  const showForgot = useCallback(() => {
    dispatch({ type: FORGOT_DIALOG });
  }, []);

  const showSuccess = useCallback((content) => {
    dispatch({ type: SUCCESS_DIALOG, content: content });
  }, []);

  const showError = useCallback((content) => {
    dispatch({ type: ERROR_DIALOG, content: content });
  }, []);

  const close = useCallback(() => {
    dispatch({ type: INIT });
  }, []);

  const showAddress = useCallback((content) => {
    dispatch({ type: ADDRESS_DIALOG, content: content });
  }, []);

  const showRate = useCallback((content) => {
    dispatch({ type: RATE_DIALOG, content: content });
  }, []);

  const showRates = useCallback((content) => {
    dispatch({ type: RATES_DIALOG, content: content });
  }, []);

  const DialogComponent = useMemo(() => {
    return state.type ? DIALOG_COMPONENTS[state.type] : null;
  }, [state.type]);

  return (
    <DialogContext.Provider
      value={{
        dispatch,
        showLogin,
        showRegister,
        showSuccess,
        showError,
        close,
        showAddress,
        showForgot,
        showRate,
        showRates,
      }}
    >
      {DialogComponent && (
        <DialogComponent content={state.content} key={state.key} />
      )}
      {children}
    </DialogContext.Provider>
  );
}

DialogContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

DialogContextProvider.defaultProps = {};

export default DialogContextProvider;
