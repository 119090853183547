import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MDCMenu } from '@material/menu';

function MdcMenu({ children, open, handleClose, classes }) {
  const menuRef = useRef();
  const menuComponent = useRef();

  const opened = useRef();

  useEffect(() => {
    menuComponent.current = new MDCMenu(menuRef.current);

    opened.current = false;

    menuComponent.current.listen('MDCMenuSurface:closed', () => {
      if (opened.current) {
        opened.current = false;

        handleClose();
      }
    });

    return () => {
      menuComponent.current.destroy();
    };
  }, [handleClose]);

  useEffect(() => {
    if (
      menuComponent.current &&
      (opened.current != open || menuComponent.current.open != open)
    ) {
      opened.current = open;
      menuComponent.current.open = open;
    }
  }, [open]);

  /* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
  return (
    <div
      className={['mdc-menu mdc-menu-surface', ...classes]
        .filter((el) => el)
        .join(' ')}
      ref={menuRef}
    >
      <ul
        aria-hidden="true"
        aria-orientation="vertical"
        className="mdc-list"
        role="menu"
        tabIndex="-1"
      >
        {children}
      </ul>
    </div>
  );
  /* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */
}

MdcMenu.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

MdcMenu.defaultProps = {
  classes: [],
  handleClose: () => {},
  open: null,
};

export default MdcMenu;
