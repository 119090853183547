import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MDCTextField } from '@material/textfield';

function MdcTextInput({
  name,
  type,
  label,
  handleChange,
  errors,
  required,
  fullwidth,
  full,
  placeholder,
  nolabel,
  outlined,
  trailingText,
  surface,
  value,
  max,
  min,
  disabled,
}) {
  const inputRef = useRef();
  const inputComponent = useRef();

  useEffect(() => {
    inputComponent.current = new MDCTextField(inputRef.current);

    return () => {
      inputComponent.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (inputComponent.current.value != value) {
      inputComponent.current.value = value;
    }
  }, [value]);

  const onChange = useCallback(
    (event) => {
      handleChange(event.target.value);
    },
    [handleChange],
  );

  return [
    <label
      className={[
        'mdc-text-field',
        nolabel && 'mdc-text-field--no-label',
        fullwidth && 'mdc-text-field--fullwidth',
        full && 'mdc-text-field--full',
        outlined && 'mdc-text-field--outlined',
        trailingText && 'mdc-text-field--has-leading-trailing',
        surface && 'mdc-text-field--surface',
      ]
        .filter((el) => el)
        .join(' ')}
      htmlFor={name}
      key={0}
      ref={inputRef}
    >
      {!outlined && <div className="mdc-text-field__ripple" />}
      <input
        aria-label={label}
        className="mdc-text-field__input"
        max={max}
        min={min}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        type={type}
        disabled={disabled}
      />
      {trailingText && (
        <span className="mdc-text-field__leading-trailing">{trailingText}</span>
      )}
      {!outlined && !nolabel && label && (
        <span className="mdc-floating-label">{label}</span>
      )}
      {!outlined && <div className="mdc-line-ripple" />}
      {outlined && (
        <div className="mdc-notched-outline">
          <div className="mdc-notched-outline__leading" />
          <div className="mdc-notched-outline__notch">
            {!nolabel && label && (
              <span className="mdc-floating-label">{label}</span>
            )}
          </div>
          <div className="mdc-notched-outline__trailing" />
        </div>
      )}
    </label>,
    <div className="errors" key={1}>
      {errors &&
        errors.map((error) => {
          return (
            <span
              className="mdc-theme--error mdc-typography--caption"
              key={error}
            >
              {error}
              {errors.length > 1 ? ' ' : ''}
            </span>
          );
        })}
    </div>,
  ];
}

MdcTextInput.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  full: PropTypes.bool,
  fullwidth: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  nolabel: PropTypes.bool,
  outlined: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  surface: PropTypes.bool,
  trailingText: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};
MdcTextInput.defaultProps = {
  disabled: null,
  errors: null,
  full: null,
  fullwidth: null,
  handleChange: () => {},
  label: null,
  max: null,
  min: null,
  nolabel: null,
  outlined: null,
  placeholder: null,
  required: null,
  surface: null,
  trailingText: null,
  type: 'text',
  value: '',
};

export default MdcTextInput;
