import { useEffect, useRef, useCallback, useReducer } from 'react';

import requestRoute, { request as raw } from '../api';

import useLocale from './useLocale';
import useAuth from './useAuth';

const SET_ERRORS = Symbol();
const INIT = Symbol();

function init() {
  return {};
}

function reducer(_, action) {
  switch (action.type) {
    case SET_ERRORS:
      return { ...action.data };
    case INIT:
      return init();
    default:
      throw new Error();
  }
}

function useApi() {
  const abortController = useRef();

  const [state, dispatch] = useReducer(reducer, null, init);

  const { locale } = useLocale();

  const { token, logout } = useAuth();

  const errorsFor = useCallback(
    (key) => {
      const errors = state.errors ? state.errors[key] || [] : [];

      return errors.map((el) => {
        return (
          {
            'validation.phone': 'Zadejte platné telefonní číslo',
          }[el] || el
        );
      });
    },
    [state.errors],
  );

  const request = useCallback(
    (route, data, params) => {
      if (abortController.current) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      return requestRoute(
        abortController.current.signal,
        route,
        locale,
        token,
        data,
        params,
      ).then(
        (response) => {
          if (response.status.status === 422) {
            dispatch({ type: SET_ERRORS, data: response.json });

            let firstError = document.querySelector('.errors .mdc-theme--error')
              .parentElement;

            firstError = firstError
              ? firstError.previousElementSibling
                ? firstError.previousElementSibling
                : firstError
              : null;

            if (firstError) {
              firstError.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
              });

              const input =
                firstError.querySelector('input') ||
                firstError.querySelector('select') ||
                firstError.querySelector('textarea');

              if (input) {
                input.focus();
              }
            }
          } else {
            dispatch({ type: INIT });
          }

          if (response.status.status === 401 && token) {
            logout();
          }

          if (response.status.ok) {
            return Promise.resolve(response);
          }

          return Promise.reject(response);
        },
        (error) => {
          throw error;
        },
      );
    },
    [locale, token, logout],
  );

  const requestUrl = useCallback(
    (url) => {
      if (abortController.current) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      return raw(
        abortController.current.signal,
        url,
        'GET',
        locale,
        token,
      ).then(
        (response) => {
          if (response.status.status === 422) {
            dispatch({ type: SET_ERRORS, data: response.json });
          } else {
            dispatch({ type: INIT });
          }

          if (response.status.status === 401 && token) {
            logout();
          }

          if (response.status.ok) {
            return Promise.resolve(response);
          }

          return Promise.reject(response);
        },
        (error) => {
          throw error;
        },
      );
    },
    [locale, token, logout],
  );

  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return { request, errorsFor, requestUrl };
}

export default useApi;
