export const API_URL = '/api/v1';

export const X_API_KEY = 'EPUJCOVNA';

export const REGISTER_REQUEST = Symbol();
export const LOGIN_REQUEST = Symbol();
export const LOGOUT_REQUEST = Symbol();
export const REFRESH_REQUEST = Symbol();
export const AUTH_FORGOT_REQUEST = Symbol();
export const AUTH_RESET_REQUEST = Symbol();
export const AUTH_CHANGE_REQUEST = Symbol();
export const AUTH_DELETE_REQUEST = Symbol();
export const USERS_UPDATE_REQUEST = Symbol();
export const ANNONCES_INDEX_REQUEST = Symbol();
export const ANNONCES_STORE_REQUEST = Symbol();
export const ANNONCES_UPDATE_REQUEST = Symbol();
export const ANNONCES_SHOW_REQUEST = Symbol();
export const ADDRESSES_INDEX_REQUEST = Symbol();
export const ORDERS_STORE_REQUEST = Symbol();
export const GEOCODE_TO_ADDRESS_REQUEST = Symbol();
export const GEOCODE_FROM_ADDRESS_REQUEST = Symbol();
export const USERS_SHOW_REQUEST = Symbol();
export const ADDRESSES_STORE_REQUEST = Symbol();
export const THREADS_INDEX_REQUEST = Symbol();
export const THREADS_SHOW_REQUEST = Symbol();
export const THREADS_UPDATE_REQUEST = Symbol();
export const THREADS_READ_REQUEST = Symbol();
export const ORDERS_INDEX_REQUEST = Symbol();
export const ORDERS_MINE_INDEX_REQUEST = Symbol();
export const ORDERS_ACCEPT_REQUEST = Symbol();
export const ORDERS_REJECT_REQUEST = Symbol();
export const ORDERS_RETURN_REQUEST = Symbol();
export const ORDERS_DELAY_REQUEST = Symbol();
export const THREADS_UNREAD_REQUEST = Symbol();
export const ANNONCES_MINE_INDEX_REQUEST = Symbol();
export const NOTIFICATIONS_UPDATE_REQUEST = Symbol();
export const HIGHLIGHT_REQUEST = Symbol();
export const HIGHLIGHT_PRICE_REQUEST = Symbol();
export const PACKAGES_BUY_REQUEST = Symbol();
export const REPORTS_STORE_REQUEST = Symbol();
export const RATES_STORE_REQUEST = Symbol();
export const CONTACT_STORE_REQUEST = Symbol();
export const ANNONCES_CAN_CREATE = Symbol();

export const REQUEST_URLS = {
  [REGISTER_REQUEST]: {
    method: 'POST',
    url: '/auth/register',
  },
  [LOGIN_REQUEST]: {
    method: 'POST',
    url: '/auth/login',
  },
  [LOGOUT_REQUEST]: {
    method: 'POST',
    url: '/auth/logout',
  },
  [REFRESH_REQUEST]: {
    method: 'POST',
    url: '/auth/refresh',
  },
  [AUTH_CHANGE_REQUEST]: {
    method: 'POST',
    url: '/auth/change',
  },
  [AUTH_DELETE_REQUEST]: {
    method: 'POST',
    url: '/auth/destroy',
  },
  [AUTH_FORGOT_REQUEST]: {
    method: 'POST',
    url: '/auth/forgot',
  },
  [AUTH_RESET_REQUEST]: {
    method: 'POST',
    url: '/auth/reset',
  },
  [USERS_UPDATE_REQUEST]: {
    method: 'POST',
    url: '/users/update',
  },
  [ANNONCES_INDEX_REQUEST]: {
    method: 'GET',
    url: '/annonces',
  },
  [ANNONCES_MINE_INDEX_REQUEST]: {
    method: 'GET',
    url: '/mine-annonces',
  },
  [ANNONCES_STORE_REQUEST]: {
    method: 'POST',
    url: '/annonces',
  },
  [ANNONCES_CAN_CREATE]: {
    method: 'POST',
    url: '/annonces/can-create',
  },
  [ANNONCES_UPDATE_REQUEST]: {
    method: 'POST',
    url: '/annonces/:annonce/update',
  },
  [ANNONCES_SHOW_REQUEST]: {
    method: 'GET',
    url: '/annonces/:annonce',
  },
  [REPORTS_STORE_REQUEST]: {
    method: 'POST',
    url: '/annonces/:annonce/problems',
  },
  [RATES_STORE_REQUEST]: {
    method: 'POST',
    url: '/annonces/:annonce/rates',
  },
  [ADDRESSES_INDEX_REQUEST]: {
    method: 'GET',
    url: '/addresses',
  },
  [ORDERS_INDEX_REQUEST]: {
    method: 'GET',
    url: '/orders',
  },
  [ORDERS_MINE_INDEX_REQUEST]: {
    method: 'GET',
    url: '/mine-orders',
  },
  [ORDERS_STORE_REQUEST]: {
    method: 'POST',
    url: '/annonces/:annonce/orders',
  },
  [GEOCODE_TO_ADDRESS_REQUEST]: {
    method: 'POST',
    url: '/geocode-to-address',
  },
  [GEOCODE_FROM_ADDRESS_REQUEST]: {
    method: 'POST',
    url: '/geocode-from-address',
  },
  [USERS_SHOW_REQUEST]: {
    method: 'GET',
    url: '/users/:user',
  },
  [ADDRESSES_STORE_REQUEST]: {
    method: 'POST',
    url: '/addresses',
  },
  [THREADS_INDEX_REQUEST]: {
    method: 'GET',
    url: '/threads',
  },
  [THREADS_SHOW_REQUEST]: {
    method: 'GET',
    url: '/threads/:thread/messages',
  },
  [THREADS_UPDATE_REQUEST]: {
    method: 'POST',
    url: '/threads/:thread/messages',
  },
  [THREADS_READ_REQUEST]: {
    method: 'POST',
    url: '/threads/:thread/read',
  },
  [ORDERS_ACCEPT_REQUEST]: {
    method: 'POST',
    url: '/orders/:order/accept',
  },
  [ORDERS_REJECT_REQUEST]: {
    method: 'POST',
    url: '/orders/:order/reject',
  },
  [ORDERS_RETURN_REQUEST]: {
    method: 'POST',
    url: '/orders/:order/return',
  },
  [ORDERS_DELAY_REQUEST]: {
    method: 'POST',
    url: '/orders/:order/delay',
  },
  [THREADS_UNREAD_REQUEST]: {
    method: 'GET',
    url: '/threads/unread',
  },
  [NOTIFICATIONS_UPDATE_REQUEST]: {
    method: 'POST',
    url: '/notification-settings',
  },
  [HIGHLIGHT_REQUEST]: {
    method: 'POST',
    url: '/annonces/:annonce/highlight',
  },
  [HIGHLIGHT_PRICE_REQUEST]: {
    method: 'GET',
    url: '/annonces/:annonce/highlight-price',
  },
  [PACKAGES_BUY_REQUEST]: {
    method: 'POST',
    url: '/packages/:package/buy',
  },
  [CONTACT_STORE_REQUEST]: {
    method: 'POST',
    url: '/contact-form',
  },
};
