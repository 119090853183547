import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const INIT = Symbol();
export const SET_ANNONCES = Symbol();
export const SET_ANNONCE = Symbol();

export const AnnonceContext = createContext();

function init() {
  return { data: [], annonce: null };
}

function reducer(state, action) {
  switch (action.type) {
    case SET_ANNONCES:
      return { ...state, data: [...action.data] };
    case SET_ANNONCE:
      return { ...state, annonce: { ...action.data } };
    case INIT:
      return init();
    default:
      throw new Error();
  }
}

function AnnonceContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, null, init);

  return (
    <AnnonceContext.Provider value={{ state, dispatch }}>
      {children}
    </AnnonceContext.Provider>
  );
}

AnnonceContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AnnonceContextProvider.defaultProps = {};

export default AnnonceContextProvider;
