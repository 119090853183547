import React, { useRef, useEffect, useCallback } from 'react';

import MdcDialog from 'mdc/MdcDialog';
import MdcTextInput from 'mdc/MdcTextInput';
import MdcButton from 'mdc/MdcButton';
import MdcLayoutGridInner from 'mdc/MdcLayoutGridInner';
import MdcLayoutGridCell from 'mdc/MdcLayoutGridCell';

import { LOGIN_REQUEST } from 'routes';

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import useDialog from 'hooks/useDialog';
import useSnack from 'hooks/useSnack';

function LoginDialog() {
  const formRef = useRef();

  const { loading, setLoading } = useLoader();

  const { token, login } = useAuth();

  const { request, errorsFor } = useApi();

  const { info } = useSnack();

  const { close, showRegister, showForgot } = useDialog();

  useEffect(() => {
    if (token) {
      close();
    }
  }, [token, info, close]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);

      request(LOGIN_REQUEST, formRef.current)
        .then(
          (response) => {
            login(response.json.data);
            info('Úspěšně přihlášeno');
            close();
          },
          () => {},
        )
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, request, login, info, close],
  );

  return (
    <MdcDialog title="Přihlásit">
      <form onSubmit={handleSubmit} ref={formRef}>
        <MdcLayoutGridInner>
          <MdcLayoutGridCell span={12}>
            <MdcTextInput
              errors={errorsFor('email')}
              full
              label="E-mailová adresa"
              name="email"
              outlined
              required
              type="email"
            />
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcTextInput
              errors={errorsFor('password')}
              full
              label="Heslo"
              name="password"
              outlined
              required
              type="password"
            />
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcButton
              big
              disabled={loading}
              fullwidth
              label="Přihlásit se"
              secondary
              type="submit"
              unelevated
            />
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcButton
              fullwidth
              handleClick={showForgot}
              label="Zapomenuté heslo"
              type="button"
            />
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcButton
              fullwidth
              handleClick={showRegister}
              label="Nemáte ještě účet? Založte si jej zde."
              type="button"
            />
          </MdcLayoutGridCell>
        </MdcLayoutGridInner>
      </form>
    </MdcDialog>
  );
}

export default LoginDialog;
