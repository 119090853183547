import React, { createContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { THREADS_UNREAD_REQUEST } from 'routes';

import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';

export const UnreadContext = createContext();

function UnreadContextProvider({ children }) {
  const { request: requestUnread } = useApi();

  const { token } = useAuth();

  const [unread, setUnread] = useState(0);

  const refreshUnread = useCallback(() => {
    if (token) {
      requestUnread(THREADS_UNREAD_REQUEST).then(
        (response) => {
          setUnread(response.json.data);
        },
        () => {},
      );
    }
  }, [requestUnread, setUnread, token]);

  useEffect(() => {
    refreshUnread();
  }, [refreshUnread]);

  return (
    <UnreadContext.Provider value={{ unread, refreshUnread }}>
      {children}
    </UnreadContext.Provider>
  );
}

UnreadContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

UnreadContextProvider.defaultProps = {};

export default UnreadContextProvider;
