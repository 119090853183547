import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { MDCFormField } from '@material/form-field';
import { MDCCheckbox } from '@material/checkbox';

function Component({
  handleChange,
  name,
  label,
  errors,
  required,
  fullwidth,
  checked,
}) {
  const inputRef = useRef();
  const inputComponent = useRef();
  const fieldRef = useRef();
  const fieldComponent = useRef();

  useEffect(() => {
    inputComponent.current = new MDCCheckbox(inputRef.current);
    fieldComponent.current = new MDCFormField(fieldRef.current);

    fieldComponent.current.input = inputComponent.current;

    return () => {
      inputComponent.current.destroy();
      fieldComponent.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (checked !== null) {
      inputComponent.current.checked = checked;
    }
  }, [checked]);

  return [
    <label
      className="mdc-form-field"
      htmlFor={name}
      key={0}
      ref={fieldRef}
      style={{ width: fullwidth ? '100%' : null }}
    >
      <div className="mdc-checkbox" ref={inputRef}>
        <input
          className="mdc-checkbox__native-control"
          name={name}
          onClick={handleChange}
          required={required}
          type="checkbox"
        />
        <div className="mdc-checkbox__background">
          <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
            <path
              className="mdc-checkbox__checkmark-path"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"
              fill="none"
            />
          </svg>
          <div className="mdc-checkbox__mixedmark" />
        </div>
        <div className="mdc-checkbox__ripple" />
      </div>
      {label && <div>{label}</div>}
    </label>,
    <div className="errors" key={1}>
      {errors &&
        errors.map((error) => {
          return (
            <span
              className="mdc-theme--error mdc-typography--caption"
              key={error}
            >
              {error}
              {errors.length > 1 ? ' ' : ''}
            </span>
          );
        })}
    </div>,
  ];
}

Component.propTypes = {
  checked: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  fullwidth: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.oneOf([PropTypes.node, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

Component.defaultProps = {
  checked: null,
  errors: null,
  fullwidth: null,
  handleChange: null,
  required: null,
};

export default Component;
