import React, { useRef, useEffect, useCallback, useState } from 'react';

import MdcLever from 'mdc/MdcLever';
import MdcDialog from 'mdc/MdcDialog';
import MdcTextInput from 'mdc/MdcTextInput';
import MdcCheckbox from 'mdc/MdcCheckbox';
import MdcButton from 'mdc/MdcButton';
import MdcLayoutGridInner from 'mdc/MdcLayoutGridInner';
import MdcLayoutGridCell from 'mdc/MdcLayoutGridCell';

import { REGISTER_REQUEST } from 'routes';

import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import useSnack from 'hooks/useSnack';
import useDialog from 'hooks/useDialog';
import useLoader from 'hooks/useLoader';

function RegisterDialog() {
  const formRef = useRef();

  const { token, login } = useAuth();

  const { request: requestRegister, errorsFor: errorsForRegister } = useApi();

  const { loading, setLoading } = useLoader();

  const { close } = useDialog();

  const { info } = useSnack();

  const [setLever] = useState(0);

  useEffect(() => {
    if (token) {
      close();
    }
  }, [token, info, close]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);

      const formData = new FormData(formRef.current);

      requestRegister(REGISTER_REQUEST, formData)
        .then(
          (response) => {
            login(response.json.data);
            info('Úspěšná registrace');
            close();
          },
          () => {},
        )
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, requestRegister, login, info, close],
  );

  return (
    <MdcDialog title="Registrace">
      <form
        className="mdc-dialog--register__form"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <MdcLever
          handleChange={setLever}
          left="Fyzická osoba"
          right="Právnická osoba"
        >
          <div style={{ marginTop: '2rem' }}>
            <MdcLayoutGridInner>
              <input name="type" type="hidden" value="INDIVIDUAL" />
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('first_name')}
                  full
                  label="Jméno"
                  name="first_name"
                  outlined
                  required
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('last_name')}
                  full
                  label="Příjmení"
                  name="last_name"
                  outlined
                  required
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('email')}
                  full
                  label="Email"
                  name="email"
                  outlined
                  required
                  type="email"
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('password')}
                  full
                  label="Heslo"
                  name="password"
                  outlined
                  required
                  type="password"
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('password_confirmation')}
                  full
                  label="Heslo znovu"
                  name="password_confirmation"
                  outlined
                  required
                  type="password"
                />
              </MdcLayoutGridCell>
            </MdcLayoutGridInner>
          </div>
          <div style={{ marginTop: '2rem' }}>
            <MdcLayoutGridInner>
              <input name="type" type="hidden" value="COMPANY" />
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('email')}
                  full
                  label="E-mailová adresa pro přihlašování"
                  name="email"
                  outlined
                  required
                  type="email"
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('password')}
                  full
                  label="Heslo"
                  name="password"
                  outlined
                  required
                  type="password"
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('password_confirmation')}
                  full
                  label="Heslo znovu"
                  name="password_confirmation"
                  outlined
                  required
                  type="password"
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('company')}
                  full
                  label="Název společnosti / půjčovny"
                  name="company"
                  outlined
                  required
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('contact_person')}
                  full
                  label="Kontaktní osoba"
                  name="contact_person"
                  outlined
                  required
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={[...errorsForRegister('address')]}
                  full
                  label="Adresa"
                  name="address"
                  outlined
                  required
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={[...errorsForRegister('address2')]}
                  full
                  label="Adresa 2"
                  name="address2"
                  outlined
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={4}>
                <MdcTextInput
                  errors={[...errorsForRegister('zip')]}
                  full
                  label="PSČ"
                  name="zip"
                  outlined
                  required
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={8}>
                <MdcTextInput
                  errors={[...errorsForRegister('city')]}
                  full
                  label="Město / Obec"
                  name="city"
                  outlined
                  required
                />
              </MdcLayoutGridCell>
              <MdcLayoutGridCell span={12}>
                <MdcTextInput
                  errors={errorsForRegister('company_identification')}
                  full
                  label="IČO"
                  name="company_identification"
                  outlined
                  required
                />
              </MdcLayoutGridCell>
            </MdcLayoutGridInner>
          </div>
        </MdcLever>
        <MdcLayoutGridInner>
          <MdcLayoutGridCell span={12}>
            <MdcCheckbox
              errors={errorsForRegister('gdpr')}
              full
              label={
                <span>
                  Odesláním souhlasím s{' '}
                  <a
                    style={{ fontWeight: '700' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/obchodni-podminky.pdf"
                  >
                    Obchodními podmínkami
                  </a>{' '}
                  a beru na vědomí{' '}
                  <a
                    style={{ fontWeight: '700' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/obchodni-podminky.pdf"
                  >
                    Podmínky ochrany osobních údajů
                  </a>
                  .
                </span>
              }
              name="gdpr"
              outlined
              required
            />
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcButton
              big
              disabled={loading}
              fullwidth
              label="Registrovat se"
              secondary
              type="submit"
              unelevated
            />
          </MdcLayoutGridCell>
        </MdcLayoutGridInner>
      </form>
    </MdcDialog>
  );
}

export default RegisterDialog;
