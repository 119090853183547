import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import MdcDialog from 'mdc/MdcDialog';
import MdcButton from 'mdc/MdcButton';
import MdcLayoutGridInner from 'mdc/MdcLayoutGridInner';
import MdcLayoutGridCell from 'mdc/MdcLayoutGridCell';
import MdcTextArea from 'mdc/MdcTextArea';

import FormField from 'fm/FormField';

import { RATES_STORE_REQUEST } from 'routes';

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';
import useDialog from 'hooks/useDialog';

import goldStar from 'assets/svg/gold_star.svg';
import grayStar from 'assets/svg/grey_star.svg';

function RateDialog({ content }) {
  const formRef = useRef();

  const { loading, setLoading } = useLoader();

  const { request, errorsFor } = useApi();

  const [done, setDone] = useState(false);

  const [goldStars, setGoldStarsState] = useState(0);

  const setGoldStars = useCallback(
    (value) => {
      if (done) {
        return;
      }

      setGoldStarsState(value);
    },
    [setGoldStarsState, done],
  );

  const [message, setMessage] = useState('');

  const { close } = useDialog();

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (done) {
        return close();
      }

      setLoading(true);

      request(
        RATES_STORE_REQUEST,
        {
          content: message ? message : null,
          rate: goldStars ? goldStars : null,
        },
        {
          annonce: content.annonce.id,
        },
      )
        .then(
          () => {
            setDone(true);

            if (content.onDone) {
              content.onDone();
            }
          },
          () => {},
        )
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, request, content, goldStars, done, setDone, message, close],
  );

  /* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
  return (
    <MdcDialog dense inputable title="Ohodnoťe průběh vypůjčení">
      <form onSubmit={handleSubmit} ref={formRef}>
        <MdcLayoutGridInner>
          <MdcLayoutGridCell span={12}>
            <div>
              <FormField label="Počet hvězdiček">
                <div>
                  {Array(goldStars)
                    .fill(null)
                    .map((_, index) => {
                      return (
                        <img
                          alt="Zlatá hvězda"
                          className="mdc-annonce-card__star mdc-annonce-card__star--hoverable"
                          key={index}
                          src={goldStar}
                          role="button"
                          onClick={() =>
                            setGoldStars(
                              goldStars === index + 1 ? 0 : index + 1,
                            )
                          }
                          onKeyDown={() => {}}
                        />
                      );
                    })}
                  {Array(4 - goldStars)
                    .fill(null)
                    .map((_, index) => {
                      return (
                        <img
                          alt="Žádná hvězda"
                          className="mdc-annonce-card__star mdc-annonce-card__star--hoverable"
                          key={index}
                          src={grayStar}
                          role="button"
                          onClick={() => setGoldStars(goldStars + index + 1)}
                          onKeyDown={() => {}}
                        />
                      );
                    })}
                </div>
                {errorsFor('rate') && (
                  <div className="errors">
                    {errorsFor('rate').map((error) => {
                      return (
                        <span
                          className="mdc-theme--error mdc-typography--caption"
                          key={error}
                        >
                          {error}
                          {errorsFor('rate').length > 1 ? ' ' : ''}
                        </span>
                      );
                    })}
                  </div>
                )}
              </FormField>
              <FormField label="Slovní ohodnocení">
                <MdcTextArea
                  errors={errorsFor('content')}
                  full
                  handleChange={setMessage}
                  max={250}
                  name="content"
                  nolabel
                  outlined
                  placeholder="Slovní ohodnocení"
                  surface
                  value={message}
                  withCounter
                  disabled={loading || done}
                />
              </FormField>
            </div>
          </MdcLayoutGridCell>
          <MdcLayoutGridCell span={12}>
            <MdcButton
              big
              disabled={loading}
              label={done ? 'Zavřít' : 'Odeslat'}
              secondary
              type="submit"
              unelevated
            />
          </MdcLayoutGridCell>
        </MdcLayoutGridInner>
      </form>
    </MdcDialog>
  );
  /* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */
}

RateDialog.propTypes = {
  content: PropTypes.shape().isRequired,
};

export default RateDialog;
