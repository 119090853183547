import React from 'react';
import PropTypes from 'prop-types';

function MdcLayoutGridCell({ children, span, desktop, tablet, phone }) {
  return (
    <div
      className={[
        'mdc-layout-grid__cell',
        span && `mdc-layout-grid__cell--span-${span}`,
        desktop && `mdc-layout-grid__cell--span-${desktop}-desktop`,
        tablet && `mdc-layout-grid__cell--span-${tablet}-tablet`,
        phone && `mdc-layout-grid__cell--span-${phone}-phone`,
      ]
        .filter((el) => el)
        .join(' ')}
    >
      {children}
    </div>
  );
}

MdcLayoutGridCell.propTypes = {
  children: PropTypes.node.isRequired,
  desktop: PropTypes.number,
  phone: PropTypes.number,
  span: PropTypes.number,
  tablet: PropTypes.number,
};

MdcLayoutGridCell.defaultProps = {
  desktop: null,
  phone: null,
  span: null,
  tablet: null,
};

export default MdcLayoutGridCell;
