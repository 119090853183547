import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import MdcDrawer from 'mdc/MdcDrawer';

import useRoutes from 'hooks/useRoutes';

import Fallback from './routes/Fallback';
import Scroller from './routes/Scroller';
import AuthRoute from './routes/AuthRoute';

const Index = lazy(() => import('./routes/Index'));
const AnnoncesIndex = lazy(() => import('./routes/AnnoncesIndex'));
const AnnoncesCreate = lazy(() => import('./routes/AnnoncesCreate'));
const AnnoncesEdit = lazy(() => import('./routes/AnnoncesEdit'));
const AnnoncesShow = lazy(() => import('./routes/AnnonceShow'));
const UsersShow = lazy(() => import('./routes/UsersShow'));
const Profile = lazy(() => import('./routes/Profile'));
const HowItWorks = lazy(() => import('./routes/HowItWorks'));
const Logout = lazy(() => import('./routes/Logout'));
const PasswordChange = lazy(() => import('./routes/PasswordChange'));
const AuthDelete = lazy(() => import('./routes/AuthDelete'));
const AuthReset = lazy(() => import('./routes/AuthReset'));
const Contact = lazy(() => import('./routes/Contact'));
const FAQ = lazy(() => import('./routes/FAQ'));
const ForBidders = lazy(() => import('./routes/ForBidders'));
const Pricing = lazy(() => import('./routes/Pricing'));

function App() {
  const { route } = useRoutes();

  return (
    <MdcDrawer>
      <Suspense fallback={<Fallback />}>
        <Scroller />
        <Switch>
          <Route component={Index} exact path="/" />
          <AuthRoute path={route('annonces.create')}>
            <AnnoncesCreate />
          </AuthRoute>
          <AuthRoute path={route('annonces.edit')}>
            <AnnoncesEdit />
          </AuthRoute>
          <Route component={AnnoncesShow} path={route('annonces.show')} />
          <Route component={AnnoncesIndex} path={route('annonces.index')} />
          <Route component={UsersShow} path={route('users.show')} />
          <AuthRoute path={route('profile')}>
            <Profile />
          </AuthRoute>
          <Route component={HowItWorks} path={route('how_it_works')} />
          <Route component={Pricing} path={route('pricing')} />
          <AuthRoute path={route('auth.logout')}>
            <Logout />
          </AuthRoute>
          <AuthRoute path={route('auth.change')}>
            <PasswordChange />
          </AuthRoute>
          <AuthRoute path={route('auth.delete')}>
            <AuthDelete />
          </AuthRoute>
          <Route component={AuthReset} path={route('auth.reset')} />
          <Route component={Contact} path={route('contact')} />
          <Route component={FAQ} path={route('faq')} />
          <Route component={ForBidders} path={route('for_bidders')} />
        </Switch>
      </Suspense>
    </MdcDrawer>
  );
}

export default App;
